import * as React from 'react';
import {useState} from 'react';
import GuessRow from './GuessRow';
import SimpleKeyboard from './SimpleKeyboard';
import TenplusKeyboard from './TenplusKeyboard';
import BoxtypeKeyboard from './BoxtypeKeyboard';
import ParensKeyboard from './ParensKeyboard';

function makeNewRow(puzzle) {
	return new Array(puzzle.numterms).fill("");
}

const endChars = ["x", "-", "+", "(", ")"];
const soloChars = ["-", "+", "(", ")"];

function Game({puzzle, inputTest}) {
	const [currPuzzle, setPuzzle] = useState(puzzle);
	const [guesses, setGuesses] = useState([makeNewRow(puzzle)]);
	const [endState, setEndState] = useState();
	const [states, setStates] = useState([]);
	const [currentBox, setCurrentBox] = useState(0);

	puzzle.setMode(inputTest === "parens");

	if (puzzle !== currPuzzle) {
		//reset the game
		setEndState(null);
		setGuesses([makeNewRow(puzzle)]);
		setStates([]);
		setPuzzle(puzzle);
		setCurrentBox(0);
	}

	const onBoxClick = (rowId, boxId) => {
		if (rowId === guesses.length - 1) {
			setCurrentBox(boxId);
		}
	}

	const row = guesses.length - 1;
	const guessRows = [];
	for (let i = 0; i < 6; ++i) {
		guessRows.push(<GuessRow key={i} rowId={i} guesses={guesses[i]} states={states[i]} current={i === row ? currentBox : null } onClick={onBoxClick} mode={inputTest} />);
	}

	const onDeleteClick = () => {
		if (endState) {
			return;
		}

		const row = guesses.length - 1;
		const guessRow = guesses[row];
		if (inputTest === "boxtype" || inputTest === "parens" || inputTest === "context") {
			const val = (currentBox < puzzle.numterms) ? guessRow[currentBox] : null;
			if (val) {
				const newRow = [...guessRow];
				newRow[currentBox] = val.slice(0, val.length - 1);
				const newGuesses = [...guesses.slice(0, row), newRow];
				setGuesses(newGuesses);
			}
			else if (currentBox > 0) {
				const curr = currentBox - 1;
				const currval = guessRow[curr];
				if (currval) {
					const newRow = [...guessRow];
					newRow[curr] = currval.slice(0, currval.length - 1);
					const newGuesses = [...guesses.slice(0, row), newRow];
					setGuesses(newGuesses);	
				}
				setCurrentBox(curr);
			}
		}
		else {
			if (guessRow.length > 0) {
				const newRow = [...guessRow];
				if (currentBox < puzzle.numterms && newRow[currentBox]) {
					newRow[currentBox] = "";
				}
				else {
					newRow[currentBox - 1] = "";
					setCurrentBox(Math.max(0, currentBox - 1));
				}
				const newGuesses = [...guesses.slice(0, row), newRow];
				setGuesses(newGuesses);
			}	
		}

	}

	const onTermClick = (term) => {
		if (endState) {
			return;
		}
		const row = guesses.length - 1;
		const guessRow = guesses[row];
		if (currentBox < puzzle.numterms) {
			if (inputTest === "parens" || inputTest === "context") {
				const val = guessRow[currentBox] || "";
				if ((soloChars.includes(term) && val.length > 0) || val.length === 3) {
					if (currentBox < puzzle.numterms - 1) {
						const newRow = [...guessRow];
						newRow[currentBox + 1] = term;
						const newGuesses = [...guesses.slice(0, row), newRow];
						setGuesses(newGuesses);
						setCurrentBox(currentBox + 2);
					}
				}
				else {
					const newRow = [...guessRow];
					newRow[currentBox] = val + term;
					const newGuesses = [...guesses.slice(0, row), newRow];
					setGuesses(newGuesses);
					if (endChars.includes(term) || val.length === 2) {
						setCurrentBox(currentBox + 1);
					}
				}
			}
			else if (inputTest === "boxtype") {
				const val = guessRow[currentBox] || "";
				if (soloChars.includes(term) && val) {
					return;
				}
				if (val.length === 3 || endChars.includes(val.slice(-1))) {
					return;
				}
				const newRow = [...guessRow];
				newRow[currentBox] = val + term;
				const newGuesses = [...guesses.slice(0, row), newRow];
				setGuesses(newGuesses);
			}
			else {
				const newRow = [...guessRow];
				newRow[currentBox] = term;
				setCurrentBox(currentBox + 1);
				const newGuesses = [...guesses.slice(0, row), newRow];
				setGuesses(newGuesses);
			}
		}	
	}

	const onEnterClick = () => {
		if (endState) {
			return;
		}

		const row = guesses.length - 1;
		const guessRow = guesses[row];
		for (let guess of guessRow) {
			if (!guess) {
				return;
			}
		}

		const newGuesses = [...guesses, makeNewRow(puzzle)];

		const results = puzzle.calcGuesses(guessRow);
		const newStates = [...states, results];

		let complete = true;
		for (let res of results) {
			if (res !== "right") {
				complete = false;
				break;
			}
		}

		if (complete) {
			setEndState("victory");
		}
		else if (row === 5) {
			setEndState("defeat");
		}

		setGuesses(newGuesses);
		setStates(newStates);
		setCurrentBox(0);
	}

	const onLeftClick = () => {
		if (currentBox > 0) {
			setCurrentBox(currentBox - 1);
		}
	}

	const onRightClick = () => {
		if (currentBox < puzzle.numterms - 1) {
			setCurrentBox(currentBox + 1);
		}
	}

	return <div className={endState}>
		<h1>{puzzle.toFactor}</h1>
		<div className='gameboard'>
			{guessRows}
		</div>
		{inputTest === "simple" && <SimpleKeyboard onDeleteClick={onDeleteClick} onTermClick={onTermClick} onEnterClick={onEnterClick} 
			onLeftClick={onLeftClick} onRightClick={onRightClick} />}
		{inputTest === "tenplus" && <TenplusKeyboard onDeleteClick={onDeleteClick} onTermClick={onTermClick} onEnterClick={onEnterClick}
			onLeftClick={onLeftClick} onRightClick={onRightClick} />}
		{(inputTest === "boxtype" || inputTest === "context") && <BoxtypeKeyboard onDeleteClick={onDeleteClick} onTermClick={onTermClick} onEnterClick={onEnterClick}
			onLeftClick={onLeftClick} onRightClick={onRightClick} />}
		{inputTest === "parens" && <ParensKeyboard onDeleteClick={onDeleteClick} onTermClick={onTermClick} onEnterClick={onEnterClick}
			onLeftClick={onLeftClick} onRightClick={onRightClick} />}
	</div>

}

export default Game;
