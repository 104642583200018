import './App.css';
import {useState, useEffect} from 'react';
import Game from './components/Game.js';
import PuzzleFactory from './PuzzleFactory';
import OptionsMenu from './components/OptionsMenu';
import Settings from './Settings';

const settingsDef = [
	{
		key: "leading",
		defaultVal: "one",
		type: "string",
		allowedVals:["one", "prime", "notprime"]
	},
	{
		key: "plusplus",
		defaultVal: true,
		type: "bool"
	},
	{
		key: "minusplus",
		defaultVal: true,
		type: "bool"
	},
	{
		key: "lastminus",
		defaultVal: true,
		type: "bool"
	},
	{
		key: "maxfactor",
		defaultVal: 9,
		type: "int",
		min: 1,
		max: 9
	},
	{
		key: "maxa",
		defaultVal: 1,
		type: "int",
		min: 1,
		max: 99
	},
	{
		key: "maxb",
		defaultVal: 99,
		type: "int",
		min: 1,
		max: 99
	},
	{
		key: "maxc",
		defaultVal: 99,
		type: "int",
		min: 1,
		max: 99
	},
	{
		key: "inputmode",
		defaultVal: "simple",
		type: "string",
		allowedVals: ["simple", "tenplus", "boxtype", "parens", "context"]
	}
];

function loadSettings() {
	const settings = Settings.loadSettings(settingsDef);

	if (!settings.plusplus && !settings.minusplus && !settings.lastminus) {
		settings.plusplus = true;
	}

	switch (settings.leading) {
		case "one":
			settings.maxfactor = Math.max(settings.maxfactor, 1);
			settings.maxtri = Math.max(settings.maxtri, 2);
			settings.maxa = 1;	
			break;
		case "prime":
			settings.maxfactor = Math.max(settings.maxfactor, 3);
			settings.maxtri = Math.max(settings.maxtri, 4);
			settings.maxa = Math.max(settings.maxa, 3);
			break;
		case "notprime":
			settings.maxfactor = Math.max(settings.maxfactor, 4);
			settings.maxtri = Math.max(settings.maxtri, 5);
			settings.maxa = Math.max(settings.maxa, 4);
			break;
		default:
			settings.maxfactor = Math.max(settings.maxfactor, 1);
			settings.maxtri = Math.max(settings.maxtri, 2);
			settings.maxa = 1;	
	}

	return settings;
}

function App() {
	const [settings] = useState(loadSettings());
	const [isMenuActive, setMenuActive] = useState(false);
	const [puzzle, setPuzzle] = useState(PuzzleFactory.createPuzzle(settings));
	const [colorMode, setColorMode] = useState(localStorage.getItem('colorMode') || "colorModeGreen");
	const [theme, setTheme] = useState(localStorage.getItem('theme') || 'themeLight');

	useEffect(() => {
		document.documentElement.className = colorMode + " " + theme;
	}, [colorMode, theme]);

	return (
		<div className="App">
			<div>
				<button onClick={() => setMenuActive(!isMenuActive)}>Options</button>
				<button onClick={() => setPuzzle(PuzzleFactory.createPuzzle(settings))}>New</button>
				<OptionsMenu 
					isMenuActive={isMenuActive} setMenuActive={setMenuActive} 
					settings={settings} 
					colorMode={colorMode} setColorMode={setColorMode} 
					theme={theme} setTheme={setTheme}
				/>
			</div>
			<Game puzzle={puzzle} colorMode={colorMode} inputTest={settings.inputmode} />
		</div>
	);
}

export default App;
