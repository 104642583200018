function plusminus(x) {
	return (x > 0 ? "+" : "-")
}

function formatterm(num, variab, leadingplus) {
	if (num === 0) {
		return "";
	}

	if ((num === 1 || num === -1) && variab) {
		if (leadingplus) {
			return plusminus(num) + variab;
		}
		else {
			return (num < 0 ? "-" : "") + variab;
		}
	}

	if (!leadingplus || num < 0) {
		return num + variab;
	}
	else {
		return "+" + num + variab;
	}
}


export default class Puzzle {
	//In the form of (ax+b)(cx+d)
	constructor(a, b, c, d) {
		this.toFactor = formatterm(a * c, "x²") + formatterm(a * d + b * c, "x", true) + formatterm(b * d, "", true);
		this.solutions = [];
		const solution = [formatterm(a, "x"), plusminus(b), Math.abs(b).toString(), formatterm(c, "x"), plusminus(d), Math.abs(d).toString()];
		this.solutions.push(solution);
		this.solutions.push([...solution.slice(3), ...solution.slice(0, 3)]);

		this.parensSolutions = [];
		for (let sol of this.solutions) {
			this.parensSolutions.push(["(", ...sol.slice(0, 3), ")", "(", ...sol.slice(3), ")"])
		}

		this.parens = false;
	}

	get numterms() {
		return this.parens ? 10 : 6;
	}

	setMode(parens) {
		this.parens = parens;
	}

	checkSolution(guesses, solution) {
		let ret = Array(this.numterms).fill(null);
		let working = [...solution];
		let score = 0;
		//first pass - check for correct places
		for (let i = 0; i < guesses.length; ++i) {
			if (guesses[i] === working[i]) {
				ret[i] = "right";
				working[i] = null;
				++score;
			}
		}

		//check for out of place
		for (let i = 0; i < guesses.length; ++i) {
			if (!ret[i]) {
				let found = false;
				for (let j = 0; j < working.length && !found; ++j) {
					if (guesses[i] === working[j]) {
						ret[i] = "place";
						working[j] = null;
						found = true;
					}
				}
				if (!found) {
					ret[i] = "wrong";
				}
			}
		}

		return {score: score, result: ret}
	}

	calcGuesses(guesses) {
		let ret;
		const solutions = this.parens ? this.parensSolutions : this.solutions;
		for (let solution of solutions) {
			let res = this.checkSolution(guesses, solution);
			if (!ret || res.score > ret.score) {
				ret = res;
			}
		}

		return ret.result;
	}
}
