import * as React from 'react';
import GuessBox from './GuessBox';
import {useCallback} from 'react';

function GuessRow({rowId, guesses = [], states = [], current, onClick, mode}) {
	const onBoxClick = useCallback(id => {
		onClick(rowId, id);
	}, [rowId, onClick]);

	const guessboxes = [];

	if (mode === "parens") {
		for (let i = 0; i < 10; ++i) {
			guessboxes.push(<GuessBox key={i} boxId={i} guessState={states[i]} value={guesses[i]} current={current === i} onClick={onBoxClick} />)
		}
	
		return <div className="guessrow">
			{guessboxes}
		</div>
	
	}
	else {
		for (let i = 0; i < 6; ++i) {
			guessboxes.push(<GuessBox key={i} boxId={i} guessState={states[i]} value={guesses[i]} current={current === i} onClick={onBoxClick} />)
		}
	
		return <div className="guessrow">
			<div className="guess paren">(</div>
			{guessboxes.slice(0, 3)}
			<div className="guess paren">)</div>
			<div className="guess paren">(</div>
			{guessboxes.slice(3)}
			<div className="guess paren">)</div>
		</div>	
	}
}

export default GuessRow;