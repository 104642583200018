import Puzzle from "./Puzzle";

function genTerm(a, b) {
	let min = 1;
	let max = 9;
	if (b) {
		min = a;
		max = b;
	}
	else if (a) {
		max = a;
	}
	return Math.floor(Math.random() * (max + 1 - min)) + min;
}

const primes = [2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 83, 87, 89, 91, 97];

function genPrime(max = 9) {
	const allowedPrimes = primes.filter(x => x <= max);
	let index = Math.floor(Math.random() * allowedPrimes.length);
	return allowedPrimes[index];
}

function gcd(a, b) {
	if (a === b) {
		return a;
	}

	if (a === 0 || b === 0) {
		return Math.max(a, b);
	}

	if (a === 1 || b === 1) {
		return 1;
	}

	const min = Math.min(a, b);
	const max = Math.max(a, b);

	return gcd(max % min, min);
}

function genTermNoGcd(a, min, max) {
	let b;
	do {
		b = genTerm(min, max);
	}
	while (gcd(a, Math.abs(b)) !== 1);

	return b;
}

const PuzzleFactory = {
	createPuzzle: function({leading = "one", plusplus, minusplus, lastminus, maxfactor = 9, maxb = 99, maxa = 9, maxc = 99}) {
		let a = 1;
		let c = 1;

		switch(leading) {
			case "one":
				break;
			case "prime":
				a = genPrime(Math.min(maxfactor, maxa));
				break;

			case "notprime":
				do {
					a = genTerm(2, Math.min(maxfactor, maxa));
					if (primes.includes(a)) {
						c = genTerm(2, Math.min(Math.floor(maxa / a), maxfactor));
					}
					else {
						c = genTerm(1, Math.min(Math.floor(maxa / a), maxfactor));
					}						
				}
				while (a * c > maxa);
				break;
			default:
				throw new Error('invalid puzzle type: ' + this.leading);
		}

		const plusMinusPaths = [];
		if (plusplus) {
			plusMinusPaths.push("plusplus");
		}
		if (minusplus) {
			plusMinusPaths.push("minusplus");
		}
		if (lastminus) {
			plusMinusPaths.push("lastminus");
		}

		if (plusMinusPaths.length === 0) {
			plusMinusPaths.push("plusplus");
		}

		let b = genTermNoGcd(a, Math.min(maxfactor, Math.floor((maxb - a) / c), maxc));
		let d = genTermNoGcd(c, Math.min(maxfactor, Math.floor((maxb - b * c) / a), Math.floor(maxc / b)));

		if (a * c > maxa || a * d + c * b > maxb || b * d > maxc) {
			throw new Error("Invalid terms: " + a + " " + b + " " + c + " " + d);
		}

		const plusMinusPath = plusMinusPaths[Math.floor(Math.random() * plusMinusPaths.length)];
		switch(plusMinusPath) {
			case "plusplus":
				break;
			case "minusplus":
				b = -b;
				d = -d;
				break;
			case "lastminus":
				if (Math.random() < 0.5) {
					b = -b;
				}
				else {
					d = -d;
				}
				break;
				
			default: throw new Error("Invalid plusminus: " + plusMinusPath);
		}

		return new Puzzle(a, b, c, d);
	}

}

export default PuzzleFactory;
