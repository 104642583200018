import * as React from 'react';

function TermInput({onClick, term, className}) {
	const onMyClick = e => {
		onClick(term);
	}

	return <button className={className} onClick={onMyClick}>{term}</button>
}

export default TermInput;
