import * as React from 'react';
import {useState} from 'react';
import TermInput from './TermInput';

function TenplusKeyboard({onTermClick, onEnterClick, onDeleteClick, onLeftClick, onRightClick}) {
    const [highlightedTen, setHighlightedTen] = useState();

    const tenterms = [];
    for (let i = 1; i < 10; ++i) {
        tenterms.push(i + "0+");
    }

    const onTenClick = term => {
        if (term === highlightedTen) {
            setHighlightedTen(null);
        }
        else {
            setHighlightedTen(term);
        }
    }

    const tenrow = tenterms.map(term => <TermInput key={term} onClick={onTenClick} term={term} className={highlightedTen === term ? "selectedbutton": ""} />)

    const xterms = ["x"];
	for (let i = 2; i < 10; ++i) {
		xterms.push(i + "x")
	}

    const onSingleClick = term => {
        if (highlightedTen) {
            onTermClick(highlightedTen.slice(0, 1) + term);            
        }
        else {
            onTermClick(term);
        }
        setHighlightedTen(null);
    }

	const xrow = xterms.map(term => <TermInput key={term} onClick={onSingleClick} term={term} />)

	const numterms = [];
	for (let i = 1; i < 10; ++i) {
		numterms.push(i.toString());
	}

	const numrow = numterms.map(term => <TermInput key={term} onClick={onSingleClick} term={term} />)

    const onSpecialClick = term => {
        setHighlightedTen(null);
        if (term === "Delete") {
            onDeleteClick();
        }
        else if (term === "Enter") {
            onEnterClick();
        }
        else {
            onTermClick(term);
        }
    }

	const oprow = [];
	oprow.push(<TermInput key="←" onClick={onLeftClick} term="←" />)
	oprow.push(<TermInput key="Delete" onClick={onDeleteClick} term="Delete" />)
	oprow.push(<TermInput key="-" onClick={onSpecialClick} term="-" />)
	oprow.push(<TermInput key="+" onClick={onSpecialClick} term="+" />)
	oprow.push(<TermInput key="Enter" onClick={onEnterClick} term="Enter" />)
	oprow.push(<TermInput key="→" onClick={onRightClick} term="→" />)

	return (
		<>
			<div className="buttonrow">{tenrow}</div>
			<div className="buttonrow">{xrow}</div>
			<div className="buttonrow">{numrow}</div>
			<div className="buttonrow">{oprow}</div>
		</>
	);
}

export default TenplusKeyboard;