const urlParsers = {
	bool(url, setting) {
		const val = url.searchParams.get(setting.key);
		if (val === 't') {
			return true;
		}
		else if (val === 'f') {
			return false;
		}
	},
	
	"string": (url, setting) => {
		return url.searchParams.get(setting.key);
	},

	"int": (url, setting) => {
		return parseInt(url.searchParams.get(setting.key));
	},
}

const validators = {
	bool(val) {
		return typeof val === "boolean";
	},
	
	"string": (val, setting) => {
		if (!typeof val === "string") {
			return false;
		}
		return (!setting.allowedVals || setting.allowedVals.includes(val));
	},

	"int": (val, setting) => {
		if (!Number.isInteger(val)) {
			return false;
		}
		return ((isNaN(setting.min) || val >= setting.min) && (isNaN(setting.max) || val <= setting.max));
	},
}

function setSettingIfValid(settingObj, setting, val) {
	if (validators[setting.type](val, setting)) {
		settingObj[setting.key] = val;
	}
	else {
		delete settingObj[setting.key];
	}
}

const Settings = {

	loadSettings(settingsDef) {
		const url = new URL(window.location);
		const urlSettings = {};
		const defaultSettings = {};
		const storageSettings = (JSON.parse(localStorage.getItem('settings')) || {});

		settingsDef.forEach(setting => {
			setSettingIfValid(urlSettings, setting, urlParsers[setting.type](url, setting));
			defaultSettings[setting.key] = setting.defaultVal;
			setSettingIfValid(storageSettings, setting, storageSettings[setting.key]);
		});

		return {...defaultSettings, ...storageSettings, ...urlSettings};
	},

	makeUrl(settings) {
		const url = new URL(window.location.origin);

		for (let key in settings) {
			let val = settings[key];
			if (typeof val === "boolean") {
				val = val ? "t" : "f";
			}
			url.searchParams.append(key, val)
		}
		return url.toString();
	}
}

export default Settings;