import * as React from 'react';
import { useState, useRef, useEffect, useCallback } from 'react';
import SideMenu from './SideMenu';
import Settings from '../Settings';


//TODO: Make the URL using searchParams
//Make a display to create it


function OptionsMenu({isMenuActive, setMenuActive, settings, colorMode, setColorMode, theme, setTheme}) {
    const [plusMinusWrong, setPlusMinusWrong] = useState(false);
    const [minFactor, setMinFactor] = useState(settings.leading === "one" ? 1 : settings.leading === "prime" ? 3 : 4)
    const [maxMaxA, setMaxMaxA] = useState(settings.leading === "one" ? 1 : 99);
    const maxARef = useRef();
    const [wantsSave, setWantsSave] = useState(false);
    const [settingsUrl, setSettingsUrl] = useState(Settings.makeUrl(settings));
    const formRef = useRef();

    const validateOptions = useCallback(() => {
        let canSave = true;
        if (!settings.plusplus && !settings.minusplus && !settings.lastminus) {
            setPlusMinusWrong(true);
            canSave = false;
        }
        else {
            setPlusMinusWrong(false);
        }

        if (!formRef.current.checkValidity()) {
            canSave = false;
        }

        return canSave;
    }, [settings]);

    useEffect(() => {
        if (wantsSave) {
            if (validateOptions()) {
                localStorage.setItem('settings', JSON.stringify(settings));
            }
            setSettingsUrl(Settings.makeUrl(settings));
            setWantsSave(false);
        }
    }, [wantsSave, settings, validateOptions]);

    const onLeadingChanged = (e) => {
		settings.leading = e.target.value;
        switch(settings.leading) {
            case "one":
                setMinFactor(1);
                setMaxMaxA(1);
                settings.maxa = 1;
                maxARef.current.value = 1;
                break;
            case "prime":
                setMinFactor(3);
                setMaxMaxA(99);
                settings.maxa = 3;
                maxARef.current.value = 3;
                break;
            case "notprime":
                setMinFactor(4);
                setMaxMaxA(99);
                settings.maxa = 4;
                maxARef.current.value = 4;
                break;
            default:
                setMinFactor(1);
                setMaxMaxA(1);
                settings.maxa = 1;
                maxARef.current.value = 1;
        }
        setWantsSave(true);
	}

	const onColorModeChanged = (e) => {
		setColorMode(e.target.value);
        localStorage.setItem('colorMode', e.target.value);
	}

    const onThemeChanged = (e) => {
        setTheme(e.target.value);
        localStorage.setItem('theme', e.target.value);
    }

    const checkChanged = (e) => {
        settings[e.target.name] = e.target.checked;
        setWantsSave(true);
    }

    const intChanged = (e) => {
        if (e.target.checkValidity()) {
            settings[e.target.name] = parseInt(e.target.value);
            setWantsSave(true);
        }
    }

    const onInputModeChanged = e => {
        settings.inputmode = e.target.value;
        setWantsSave(true);
    }

    const closeMenu = () => {
        if (validateOptions()) {
            setMenuActive(false);
        }
    }

    const onClickUrl = e => {
        e.target.select();
    }

    const onShare = useCallback(e => {
        navigator.share({
            title: "Factordle Puzzle Settings",
            url: settingsUrl
        });
    }, [settingsUrl]);

    const plusMinusClasses = "options " + (plusMinusWrong ? "optionsBad" : "")

    return (
        <SideMenu isActive={isMenuActive} onLeave={closeMenu}>
            <form ref={formRef}>
            <div className='options' onChange={onLeadingChanged}>
                <label><input type="radio" value="one" name="leadingCoeff" defaultChecked={settings.leading === "one"} />Term 1 is one</label><br />
                <label><input type="radio" value="prime" name="leadingCoeff" defaultChecked={settings.leading === "prime"} />Term 1 is prime</label><br />
                <label><input type="radio" value="notprime" name="leadingCoeff" defaultChecked={settings.leading === "notprime"} />Term 1 is composite</label><br />
            </div>
            <br />
            <div className={plusMinusClasses}>
                <label><input type="checkbox" name="plusplus" onChange={checkChanged} defaultChecked={!!settings.plusplus} />All terms positive</label><br />
                <label><input type="checkbox" name="minusplus" onChange={checkChanged} defaultChecked={!!settings.minusplus} />Term 2 negative, term 3 positive</label><br />
                <label><input type="checkbox" name="lastminus" onChange={checkChanged} defaultChecked={!!settings.lastminus} />Term 3 negative</label><br />
            </div>
            <br />
            <div className='options'>
                Maximum factor coefficients in answer:<br />
                <input type="number" max={99} min={minFactor} step={1} required name="maxfactor" onChange={intChanged} defaultValue={settings.maxfactor}/><br />
            </div>
            <br />
            <div className='options'>
                Maximum a:<br />
                <input type="number" ref={maxARef} max={maxMaxA} min={minFactor} step={1} required name="maxa" onChange={intChanged} defaultValue={settings.maxa}/><br />
            </div>
            <div className='options'>
                Maximum b:<br />
                <input type="number" max={99} min={minFactor + 1} step={1} required name="maxb" onChange={intChanged} defaultValue={settings.maxb}/><br />
            </div>
            <div className='options'>
                Maximum c:<br />
                <input type="number" max={99} min={minFactor} step={1} required name="maxc" onChange={intChanged} defaultValue={settings.maxc}/><br />
            </div>
            <br />
            <div className='options'>
                Puzzle Settings URL:<input type="text" className='optionsUrl' readOnly={true} value={settingsUrl} onClick={onClickUrl} /> 
                {navigator.share && <button className='share' type='button' onClick={onShare}>Share</button>}
            </div>
            <br /><br />
            <div className = 'options' onChange={onColorModeChanged}>
                <label><input type="radio" value="colorModeGreen" name="colorMode" defaultChecked={colorMode === "colorModeGreen"} />Color Mode: Green/Yellow (default)</label><br />
                <label><input type="radio" value="colorModeBlue" name="colorMode" defaultChecked={colorMode === "colorModeBlue"} />Color Mode: Blue/Orange</label><br />
            </div>
            <br />
            <div className = 'options' onChange={onThemeChanged}>
                <label><input type="radio" value="themeLight" name="theme" defaultChecked={theme === "themeLight"} />Light Mode</label><br />
                <label><input type="radio" value="themeDark" name="theme" defaultChecked={theme === "themeDark"} />Dark Mode</label><br />
            </div>
            <br />
            <div className = 'options' onChange={onInputModeChanged}>
                Input Mode:<br />
                <label><input type="radio" value="simple" name="theme" defaultChecked={settings.inputmode === "simple"} />Simple</label><br />
                <label><input type="radio" value="tenplus" name="theme" defaultChecked={settings.inputmode === "tenplus"} />10+</label><br />
                <label><input type="radio" value="boxtype" name="theme" defaultChecked={settings.inputmode === "boxtype"} />Type in Box</label><br />
                <label><input type="radio" value="parens" name="theme" defaultChecked={settings.inputmode === "parens"} />Hide Parens</label><br />
                <label><input type="radio" value="context" name="theme" defaultChecked={settings.inputmode === "context"} />Box type, move forward based on context</label><br />
            </div>
            </form>
        </SideMenu>
)
}

export default OptionsMenu;
