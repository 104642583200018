import * as React from 'react';
import TermInput from './TermInput';

function BoxtypeKeyboard({onTermClick, onEnterClick, onDeleteClick, onLeftClick, onRightClick}) {

	const numterms = [];
	for (let i = 0; i < 10; ++i) {
		numterms.push(i.toString());
	}

	const numrow = numterms.map(term => <TermInput key={term} onClick={onTermClick} term={term} />)

	const oprow = [];
	oprow.push(<TermInput key="←" onClick={onLeftClick} term="←" />)
	oprow.push(<TermInput key="Delete" onClick={onDeleteClick} term="Delete" />)
	oprow.push(<TermInput key="x" onClick={onTermClick} term="x" />)
	oprow.push(<TermInput key="-" onClick={onTermClick} term="-" />)
	oprow.push(<TermInput key="+" onClick={onTermClick} term="+" />)
	oprow.push(<TermInput key="Enter" onClick={onEnterClick} term="Enter" />)
	oprow.push(<TermInput key="→" onClick={onRightClick} term="→" />)

	return (
		<>
			<div className="buttonrow">{numrow}</div>
			<div className="buttonrow">{oprow}</div>
		</>
	);
}

export default BoxtypeKeyboard;