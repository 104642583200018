import * as React from 'react';
import {useCallback} from 'react';

function GuessBox({boxId, guessState, value, current, onClick}) {
	const classes = "guess guessbox " + guessState + (current ? " current" : "") + (value && value.length > 2 ? " small" : "");
	const onBoxClick = useCallback(() => {onClick(boxId)}, [onClick, boxId]);

	return <div onClick={onBoxClick} className={classes}>{value}</div>
}

export default GuessBox;